<template>
<div>
    <div class="container mt-4">
        <!--para depurar erros, retire o comentario da linha abaixo-->
    <!--<p>{{tratarMensagens}}</p>-->

        <v-progress-linear v-if="!carregado"
            indeterminate
            color="cyan"
        ></v-progress-linear>
    <card>
        <template v-slot:btn>

        <template>
            <div>
                <v-app-bar
                color="primary"
                small
                dense
                dark
                >

                
                
                <v-btn text small>
                <v-badge v-if="notificacaoCor != ''"
                    color="green"
                    small
                    :content="notificacaoCor.length"
                >
                    <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#COR">COR</a>
                </v-badge>
                    <a v-else class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#COR">COR</a>
                </v-btn>

                    <v-btn text small>
                        <v-badge v-if="notificacaoSpeci != ''"
                            color="green"
                            small
                            :content="notificacaoSpeci.length"
                        >
                            <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#SPECI">SPECI</a>
                        </v-badge>
                            <a v-else class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#SPECI">SPECI</a>
                    </v-btn>

                <v-btn text small>
                        <v-badge v-if="notificacaoErros"
                            color="green"
                            small
                            :content="notificacaoErros"
                        >
                            <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#ERROS">ERRO</a>
                        </v-badge>
                        <a v-else class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#ERROS">ERRO</a>
                </v-btn>

                <v-btn text small>
                        <v-badge v-if="notificacaoAusente"
                            color="green"
                            small
                            :content="notificacaoAusente"
                        >
                            <a class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#ausente">AUSENTE</a>
                        </v-badge>
                            <a v-else class="text-decoration-none text-light" data-bs-toggle="modal" data-bs-target="#ausente">AUSENTE</a>
                </v-btn>

                <v-spacer></v-spacer>

                <v-menu
                    left
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-app-bar-nav-icon></v-app-bar-nav-icon>
                    </v-btn>
                    </template>

                    <v-list>

                    <v-list-item>
                        <v-list-item-title>
                            <a class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#criar-grupo">Criar grupo</a>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title>
                            <a class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#listarGrupo">Atualizar grupo</a>
                        </v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-app-bar>
            </div>
        </template>


        </template>
        
        <template v-slot:conteudo>
        <div class="p-4 mb-4 shadow-lg">
            <v-form>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="local" value="localidades" v-model="consulta">
                    <label class="form-check-label" for="local">
                        Localidade(s)
                    </label>
                    </div>
                    <div class="form-check ">
                    <input class="form-check-input" type="radio" id="grupo" value="grupo" v-model="consulta">
                    <label class="form-check-label" for="grupo">
                        Grupo
                    </label>
                </div>

                <hr class="mb-5">


            <v-row v-if="consulta == 'localidades'">
                <v-col lg="8" xs="12">
                <v-textarea
                placeholder="SBRF,SBMO,SBAR"
                class="mx-2"
                label="Localidade(s)"
                rows="1"
                prepend-icon="mdi-map-marker"
                v-model="localidade"
                ></v-textarea>
                </v-col>
            </v-row> 

            <v-row v-if="consulta == 'grupo'">
                <v-col lg="3" xs="12">
                    <v-text-field
                    prepend-icon="mdi-map-marker"
                    label="Grupo"
                    hide-details="auto"
                    v-model="busca"
                    ></v-text-field>
                </v-col>
            </v-row>   

            <template>
                <v-row class="mb-5">
                
                <v-col lg="4"> 
                <v-text class="text-primary">
                    Data Inicial
                </v-text>               
                    <v-date-picker width="267" class="border border-primary" locale="brazil" v-model="dataIni"></v-date-picker>
                </v-col>
                
                <v-col lg="4"> 
                <v-text class="text-primary">
                    Data Final
                </v-text>               
                    <v-date-picker width="267" class="border border-primary" locale="brazil" v-model="dataFin"></v-date-picker>
                </v-col>
            <v-col lg="2" xs="12">
            <v-select
            :items="horaSelect"
            label="Hora Ini."
            v-model="horaIni"
            ></v-select>

            </v-col>
            <v-col lg="2" xs="12">
            <v-select
            :items="horaSelect"
            label="Hora Fin."
            v-model="horaFin"
            ></v-select>

            </v-col>

                </v-row>
            </template>

            <v-btn
              color="primary"
              large
              dark
              @click="consultar"
            >
              <a href="#trazerCursor" class="text-light text-decoration-none" @click="consultar">Consultar <v-icon>mdi-arrow-right</v-icon></a>
            </v-btn>

            </v-form>
        </div>
        </template>
    </card>

        <v-alert 
            v-if="msgErroDeData"
            color="orange"
            dismissible
            type="warning">
            <strong>{{msgErroDeData}}</strong>
        </v-alert> 

        <div class="table-responsive">
        <table class="table table-hover table-bordered col" v-if="carregado && metarVerificacao">
            <thead >
                <tr class="col" style="text-align: center; background-color: #6AAAFF;">
                <th  scope="col" class="col-10 text-light fs-3"> <strong> MENSAGEM </strong></th>      
                <th  scope="col" class="col-2 text-light fs-3">STATUS</th>     
                </tr>
            </thead>                    
            <tbody v-if="metarVerificacao && carregado">
                <tr v-for="(mensagem, index) in exibirMetar" :key="index">
                    <td v-if="errosMetar[index] == 'Ausente' " class="fs-5 text-danger" :id="'cursor'+[index]">{{mensagem}}</td>
                    <td v-else-if="mensagem.includes(' COR ') == true" class="fs-5 text-success" :id="'cursor'+[index]">{{mensagem}}</td>
                    <td v-else-if="mensagem.includes('SPECI ') == true" class="fs-5 text-primary" :id="'cursor'+[index]">{{mensagem}}</td>
                    <td v-else class="fs-5" :id="'cursor'+[index]">{{mensagem}}</td>
                    <!-- Button trigger modal -->
                        <td class=" text-center "><button @click="abrirModal(explicarErros[index])" class="fs-6 btn btn-link text-danger" type="button" data-bs-toggle="modal" data-bs-target="#errosMetar">
                        {{errosMetar[index]}} 
                        </button></td> 
                </tr>
            </tbody>
        </table>
</div>
<!--Modal para mostrar erros do metar-->
        <modal
        :titulo="'Informação'"
        :mensagemModal="mensagemModal"
        identificacao="errosMetar"
        cor="danger"
        />

<!--Modal para cor-->
        <modal v-if="filtraCOR != ''"
        :titulo="'Mensagens com correção'"
        identificacao="COR"

        cor="danger"
        >
            <table class="table table-hover">
                <thead>
                    <tr class="col text-center bg-secondary text-light">
                    <th  scope="col" >Local</th>      
                    <th  scope="col" >Hora</th>     
                    </tr>
                </thead>                    
                <tbody>
                    <tr v-for="(mensagem, index) in localCor" :key="index">
                        <td class="text-center" v-if="localCor[index]"><a :href="'#cursor'+[index-5]">{{localCor[index]}}</a></td>
                        <td class="text-center" v-if="horaCor[index]">{{horaCor[index]}}</td>
                    </tr>
                </tbody>
            </table>
        </modal>

        <modal v-else
        :titulo="'Mensagens com correção'"
        identificacao="COR"

        cor="danger"
        >
            <p v-if="testeParaConsultaModal" class="text-primary">Nenhum COR encontrado!! <i class="far fa-thumbs-up"></i></p>
            <p v-else class="text-secondary">Realize uma consulta para gerar Informações!</p>
        </modal>


        <!--Modal para SPECI-->

        <!--Abrirá esse modal quando encontrar SPECI-->
        <modal v-if="filtraSpeci != ''"
        :titulo="'Mensagens especiais'"
        identificacao="SPECI"
        cor="danger"
        >
            <table class="table table-hover">
                <thead>
                    <tr class="col text-center bg-secondary text-light">
                    <th  scope="col" >Local</th>      
                    <th  scope="col" >Hora</th>     
                    </tr>
                </thead>                    
                <tbody>
                    <tr v-for="(mensagem, index) in localSpeci" :key="index">
                        <td class="text-center" v-if="localSpeci[index]"><a :href="'#cursor'+[index-5]">{{localSpeci[index]}}</a></td>
                        <td class="text-center" v-if="horaSpeci[index]">{{horaSpeci[index]}}</td>
                    </tr>
                </tbody>
            </table>
        </modal>

        <!--Abrirá esse modal quando não encontrar SPECI-->
        <modal v-else
        :titulo="'Mensagens especiais'"
        identificacao="SPECI"
        cor="danger"
        >
          <p v-if="testeParaConsultaModal" class="text-primary">Nenhum SPECI encontrado!! <i class="far fa-thumbs-up"></i></p>
          <p v-else class="text-secondary">Realize uma consulta para gerar Informações!</p>
        </modal>


    <!--Modal para ERROS-->

    <!--Abrirá esse modal toda vez que encontrar erros-->
        <modal v-if="filtarErros != ''"
        :titulo="'Mensagens com erros'"
        identificacao="ERROS"
        cor="danger"
        >
            <table class="table table-hover">
                <thead>
                    <tr class="col text-center bg-secondary text-light">
                    <th  scope="col" >Local</th>      
                    <th  scope="col" >Hora</th>     
                    </tr>
                </thead>                    
                <tbody>
                    <tr v-for="(mensagem, index) in exibirMetar" :key="index">
                        <!-- Como estou puxando de exibirMetar que devolve o metar igual ta na REDEMET
                             Preciso fazer a verificação do COR para incluir mais um posição -->
                        <td class="text-center" v-if="errosMetar[index] != null && errosMetar[index] != 'Ausente' && mensagem.split(' ')[1] == 'COR'"><a :href="'#cursor'+[index-5]">{{mensagem.split(' ')[2]}}</a></td>
                        <td class="text-center" v-if="errosMetar[index] != null && errosMetar[index] != 'Ausente' && mensagem.split(' ')[1] != 'COR'"><a :href="'#cursor'+[index-5]">{{mensagem.split(' ')[1]}}</a></td>
                        <td class="text-center" v-if="errosMetar[index] != null && errosMetar[index] != 'Ausente' && mensagem.split(' ')[1] == 'COR'">{{mensagem.split(' ')[3]}}</td>
                        <td class="text-center" v-if="errosMetar[index] != null && errosMetar[index] != 'Ausente' && mensagem.split(' ')[1] != 'COR'">{{mensagem.split(' ')[2]}}</td>
                    </tr>
                </tbody>
            </table>
        </modal>

<!--Abrirá esse modal quando não encontrar erros-->
        <modal v-else
        :titulo="'Mensagens com erros'"
        identificacao="ERROS"
        cor="danger"
        >
          <p v-if="testeParaConsultaModal" class="text-primary">Nenhum erro encontrado!! <i class="far fa-thumbs-up"></i></p>
          <p v-else class="text-secondary">Realize uma consulta para gerar Informações!</p>
        </modal>



<!--Modal para ausente-->
<!--Abrirá esse modal toda vez que encontrar Ausentes-->
        <modal v-if="filtarAusentes != ''"
        :titulo="'Informações ausentes no banco'"
        identificacao="ausente"
        cor="danger"
        >
            <table class="table table-hover">
                <thead>
                    <tr class="col text-center bg-secondary text-light">
                    <th  scope="col" >Local</th>      
                    <th  scope="col" >Hora</th>     
                    </tr>
                </thead>                    
                <tbody>
                    <tr v-for="(mensagem, index) in exibirMetar" :key="index">
                        <td class="text-center" v-if="errosMetar[index] == 'Ausente'"><a :href="'#cursor'+[index-5]">{{mensagem.split(' ')[3].substring(1,5)}}</a></td>
                        <td class="text-center" v-if="errosMetar[index] == 'Ausente'">{{mensagem.split(' ')[7]}}</td>
                    </tr>
                </tbody>
            </table>
        </modal>

<!--Abrirá esse modal quando todas as mensagens estiverem na rede-->
        <modal v-else
        :titulo="'Informações ausentes no banco'"
        identificacao="ausente"
        cor="danger"
        >
            <p v-if="testeParaConsultaModal" class="text-primary">Não há mensagens ausentes!! <i class="far fa-thumbs-up"></i></p>
            <p v-else class="text-secondary">Realize uma consulta para gerar Informações!</p>   
        </modal>

        <!--modal para grupo-->
        <modal
        :titulo="'Login de acesso'"
        identificacao="login"
        cor="danger"
        >
        <login-token/>
        </modal>

        <modal
        :titulo="'Grupo'"
        identificacao="criar-grupo"
        cor="danger"
        >
        <Grupo/>
        </modal>

        <modal
        :titulo="'Atualizar grupo'"
        identificacao="listarGrupo"
        cor="danger"
        >
        <listar-grupo/>
        </modal>
    


</div>


</div>
</template>

<script>

import axios from  'axios'
import apiUrlmetar from './../APIs/apiUrl'
import modal from './../components/modal.vue'
import card from './../components/card.vue'
import loginToken from './../components/loginToken.vue'
import Grupo from './../components/Grupo.vue'

import listarGrupo from './../components/listarGrupo.vue'

import {createNamespacedHelpers} from 'vuex'
import {mapState} from 'vuex'

const {mapActions, mapGetters} = createNamespacedHelpers('_store')

export default {
    components: {
        modal,
        card,
        loginToken,
        Grupo,
        listarGrupo
    },
    /*beforeRouteLeave (to, from, next) {
        const confirmar = window.confirm('Deseja realmente sair dessa área da página?')
        next(confirmar)
    },*/
    data() {
        return {
            horaSelect: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            localidade: undefined,
            grupo: undefined,
            horaIni:undefined,
            horaFin:undefined,
            dataIni:'',
            dataFin:'',
            mensagemErro: undefined,
            mensagemModal: '',
            carregado: true,
            verificarData: undefined,
            msgErroDeData: undefined,
            testeParaConsultaModal: false,
            busca: '',
            consulta: 'localidades',
            //token: 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ncnVwby1sb2NhbGlkYWRlcy5oZXJva3VhcHAuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjQwMzczMTA3LCJuYmYiOjE2NDAzNzMxMDcsImp0aSI6IlFEMHdyMUttMlRyQzB0SVIiLCJzdWIiOjIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.Fu5yOAhPGSCGTUzF0ZS_cpp5LCm9MP7lZf2_4SLZV98' 
        }    
    },
    methods: {
        ...mapActions(['pegarMetar']),
        consultar () {
            if(this.consulta == 'grupo'){
                this.localidade = ''
            }else{
                this.busca = ''
            }

            var localidades = ''
            if(this.consultaGrupo && this.busca){
                if(this.consultaGrupo != ''){
                    localidades = this.consultaGrupo[0].localidades.replace(/ /g,'')
                }else{
                    localidades = this.busca + ' não existe'
                }
                    
                
            }else if(this.localidade){
                 localidades = this.localidade.replace(/ /g,'')
            }else{
                localidades = this.busca + ' não existe'
            }
            this.verificarData = (parseInt(this.formatarDataFinal + this.horaFin)) - (parseInt(this.formatarDataInicial + this.horaIni))
            if(this.verificarData < 0){
                this.msgErroDeData = 'Data e hora final menor que a data e hora inicial'
            }else if(this.verificarData >= 100){
                this.msgErroDeData = 'A consulta deve ser feita com um intervalo de 24h.'
            }else{
            this.carregado = false
            axios.get(apiUrlmetar.apiMetarConferencia.apiUrlmetar(localidades,this.formatarDataInicial,this.horaIni,this.formatarDataFinal,this.horaFin))
                .then((response) => {
                    this.pegarMetar(response.data)
                    this.carregado = true
                    this.testeParaConsultaModal = true
                }).catch((error) => {
                    if(error.request){
                            this.mensagemErro = 'Erro ao tentar comunicar com o servidor!! '+ error
                    }
                    else if(error.response){
                            this.mensagemErro = 'O servidor retournou um erro como resposta!! '+ error
                        }
                    else {
                        this.mensagemErro = 'Erro ao fazer requisição ao servidor!! '+ error
                        }
                })
            }    
        },
        abrirModal(valor) {
            this.mensagemModal = valor
        },
        fecharAlerta(){
            this.msgErroDeData = undefined
        }
    },
    computed: {
        ...mapState('_store',['metarVerificacao']),
        ...mapState('GrupoMetar',['grupoCentral', 'token']),
        ...mapGetters([
        'exibirMetar', 
        'errosMetar', 
        'explicarErros', 
        'tratarMensagens',
        'horaCor',
        'localCor',
        'horaSpeci',
        'localSpeci',
        ]),

        notificacaoCor(){
            if(this.exibirMetar){
                return this.exibirMetar.filter(metar => {
                    if(metar.includes(' COR ') == true ){
                        return metar
                    }
                })
            }
            return ''
        },

        notificacaoSpeci(){
            if(this.exibirMetar){
                return this.exibirMetar.filter(metar => {
                    if(metar.includes('SPECI') == true ){
                        return metar
                    }
                })
            }
            return ''
        },

        notificacaoErros(){
            var valor = 0;
            for(let c = 0; c <= this.errosMetar.length; c++){
                if(this.errosMetar[c] != null && this.errosMetar[c] != 'Ausente'){
                    valor++
                }
            }
            return valor
        },

        notificacaoAusente(){
            var valor = 0;
            for(let c = 0; c <= this.errosMetar.length; c++){
                if(this.errosMetar[c] == 'Ausente'){
                    valor++
                }
            }
            return valor
        },

        filtraCOR(){
            if(this.exibirMetar){
                return this.exibirMetar.filter(metar => {
                    if(metar.includes(' COR ') == true ){
                        return metar
                    }
                })
            }
            return ''
        },
        filtraSpeci(){
            if(this.exibirMetar){
                return this.exibirMetar.filter(metar => {
                    if(metar.includes('SPECI') == true ){
                        return metar
                    }
                })
            }
            return ''
        },
        filtarErros(){
            if(this.errosMetar){
                return this.errosMetar.filter(erros => {
                    if(erros != '' && erros != 'Ausente'){
                        return erros
                    }
                })
            }
            return ''
        },
        filtarAusentes(){
            if(this.errosMetar){
                return this.errosMetar.filter(erros => {
                    if(erros != '' && erros == 'Ausente'){
                        return erros
                    }
                })
            }
            return ''
        },
        formatarDataInicial () {
            return this.dataIni.toString().replace(/-/g,'')
        },
        formatarDataFinal () {
            return this.dataFin.toString().replace(/-/g,'')
        },
        consultaGrupo(){
            var buscando = this.busca.toLowerCase()
            if(this.grupoCentral){
            return  this.grupoCentral.filter(t => {
                if(t.nome == buscando){                    
                    return t
                }
            })
        }
        return ''
        },
        }
}
</script>

<style scoped>
a {
    cursor: pointer;
}

.direitos{
        color: #ffffff;
        font-size: 15px;
        text-decoration: none;
    }

</style>