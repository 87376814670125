const localCor = (state, getters) => {
    if(getters.tratarMensagens){
    
    var mensagemTratada =  getters.tratarMensagens   

        var corLocal = []

        for(let c = 0; c < mensagemTratada.length ; c++){
            if(mensagemTratada[c][1] == 'COR'){
                corLocal[c] = mensagemTratada[c][2]
            }

        }

        return corLocal

    }
}

export default localCor