<template>
    <div class="container">
    <div v-if="alerta" :class="alertaMsg" role="alert">
        <ul v-for="(m, index) in msg" :key="index">
            <li>{{m[0]}}</li>
        </ul>
    </div>

    <form>
            <div class="row">
                <div class="col-10">
                    <v-text-field label="Buscar grupo" v-model.lazy="busca">
                    </v-text-field>
                </div>
                
                <div class="col-2">
                <v-btn color="green" dark type="button" @click="buscar">
                <v-icon>mdi-magnify</v-icon>
                </v-btn>
                </div>
            </div>
        </form>
        <hr>
        <div v-if="buscarGrupo">
        <div v-for="grupo in buscarGrupo" :key="grupo.id">
            <form @submit.prevent="Atualizar(grupo)">
                <v-text>
                    <span class="text-primary">Grupo: </span>{{grupoAtualizado.nome}}
                </v-text>
                <div class="form-group mt-3">
                <v-textarea
                class="mx-2"
                label="Localidade(s)"
                rows="3"
                prepend-icon="mdi-map-marker"
                v-model="grupoAtualizado.localidades"
                ></v-textarea>
            </div>
                <div class="form-group">
                    <v-text-field label="Senha" type="password" v-model="grupoAtualizado.senha">
                    </v-text-field>
                </div>
                <div class="form-group mt-2">
                    <button class="btn btn-sm btn-primary ps-5 pe-5"><v-icon dark>mdi-autorenew</v-icon></button>
                </div>
            </form>
        </div>
        </div>
        <div v-else-if="!busca">
            
        </div>
        <div v-else>
            <p>Nenhum grupo encontrado</p>
        </div>

        
    </div>
</template>

<script>

import axios from  'axios'
import apiUrl from '../APIs/apiUrl'
import {createNamespacedHelpers} from 'vuex'

const {mapState,mapActions} = createNamespacedHelpers('GrupoMetar')


export default {
    data (){
        return {
            grupoAtualizado: {nome: '', localidades: '', senha: ''},
            busca: '',
            msg: '',
            alerta: false,
            valorCapturado: '',
        }
    },
    created() {
        axios.get(apiUrl.grupoMetar.apiUrlGrupoMetar, {headers: {Authorization: this.token }})
        .then( response => {
            this.carregarGrupo(response.data)
        })
    },
    computed: {
        ...mapState(['grupoCentral', 'token']),
        buscarGrupo() {
            var busca = this.valorCapturado
            var filtarGrupo = this.grupoCentral.filter(grupo => grupo.nome == busca);
            if(filtarGrupo.length >= 1){
                return filtarGrupo
            }
            return ''
        },
        alertaMsg(){
            if(this.msg == 'Atualizado com sucesso'){
                return 'alert alert-primary'
            }
            return 'alert alert-danger'
        },
    },
    methods: {
        ...mapActions(['atualizarGrupo', 'carregarGrupo']),
        buscar(){
            this.valorCapturado = this.busca.toLowerCase()
            if(this.buscarGrupo){
            this.grupoAtualizado = {nome: this.buscarGrupo[0].nome, localidades: this.buscarGrupo[0].localidades, senha: ''}
            }
            setTimeout(() => {
            this.busca = ''
                
            }, 2000);
        },
        Atualizar(grupo){

            axios.get(apiUrl.grupoMetar.apiUrlGrupoMetar+'/'+grupo.id, {headers: {Authorization: this.token}})
            .then(response => {

                if(this.grupoAtualizado.senha == response.data.senha || this.grupoAtualizado.senha == '' || this.grupoAtualizado.senha.length < 6) {
            axios.put(apiUrl.grupoMetar.apiUrlGrupoMetar+'/'+grupo.id, this.grupoAtualizado, {
                headers: {Authorization: this.token}
            } )
            .then((response) => {
                this.alerta = true
                this.msg = [['Atualizado com sucesso']]
                this.grupoAtualizado = ''
                this.busca = ''
                this.atualizarGrupo(response.data)
            })
            .catch(erro => {
                this.alerta = true
                this.msg = erro.response.data
                setTimeout(() => {
                    this.alerta = false                
                }, 3000);
            })
                }
                else{
                    this.alerta = true
                    setTimeout(() => {
                    this.alerta = false
                    }, 3000);
                    this.msg = [['Senha inválida']]
                }
            })

            
        }
    }
    
}
</script>