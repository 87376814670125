const horaCor = (state, getters) => {
    if(getters.tratarMensagens){
    
    var mensagemTratada =  getters.tratarMensagens   

        var hora = []

        for(let c = 0; c < mensagemTratada.length ; c++){
            if(mensagemTratada[c][1] == 'COR'){
                hora[c] = mensagemTratada[c][3]
            }

        }

        return hora

    }
}

export default horaCor