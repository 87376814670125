<template>
    <div class="container">
    <div v-if="alerta" :class="alertaMsg" role="alert">
        <ul v-for="(m, index) in msg" :key="index">
            <li>{{m[0]}}</li>
        </ul>
    </div>
        <form @submit="criar">
            <div class="form-group">
                <v-text-field label="Nome" v-model="grupo.nome"></v-text-field>
            </div>
            <div class="form-group">
                <v-text-field label="Senha" type="password" v-model="grupo.senha"></v-text-field>
            </div>
            <div class="form-group">

                <v-textarea
                class="mx-2"
                label="Localidade(s)"
                rows="3"
                prepend-icon="mdi-map-marker"
                v-model="grupo.localidades"
                ></v-textarea>
            </div>
            <v-btn color="green" dark class="mt-3" type="submit">
                Criar
            </v-btn>
        </form>
    </div>
</template>

<script>

import axios from  'axios'
//import bcrypt from 'bcryptjs'
import apiUrl from './../APIs/apiUrl'
import {createNamespacedHelpers} from 'vuex'

const {mapState, mapActions} = createNamespacedHelpers('GrupoMetar')

export default {
    data () {
        return {
            grupo: {nome: '', localidades: '', senha: ''},
            alerta: false,
            msg: ''
        }
    },
    computed: {
        ...mapState(['token']),
        alertaMsg(){
            if(this.msg == 'Criado com sucesso'){
                return 'alert alert-primary'
            }
            return 'alert alert-danger'
        }
    },
    methods: {
        ...mapActions(['criarGrupo']),
        criar() {
            
                    axios.post(apiUrl.grupoMetar.apiUrlGrupoMetar, {nome: this.grupo.nome.toLowerCase(), localidades: this.grupo.localidades, senha: this.grupo.senha},
             {headers: {Authorization: this.token }})
        .then((response) => {
            this.alerta = true
            this.msg = [['Criado com sucesso']]
            this.criarGrupo(response.data)

            this.grupo = {nome: '', localidades: '', senha: ''}
        })
        .catch(erro => {
            this.alerta = true
                this.msg = erro.response.data
                setTimeout(() => {
                    this.alerta = false                
                }, 3000);
        })
        }
            
        }
}
</script>
                

                //const round = 10
            //const password = this.grupo.senha
               // bcrypt.hash(password, round, (err, hash) => {
                   // if(err){
                  //      console.error(err)
                  //      return
                  //  }
                  // })