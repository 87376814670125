const tratarMensagens = state => {
        if(state.metarVerificacao) {
            var valor = []
            
            for(let c = 1; c < state.metarVerificacao.split('- ').length ; c++){
                valor[c-1] = state.metarVerificacao.split('- ')[c];
            }//vou ter que criar um for dentro do outro para poder pegar o valor de cada posição


        

            var valor2 = []
            for(let c = 0; c < valor.length; c++){
                    valor2[c] = valor[c].split('=')[0]
            }
            
            var valor3 = []

            for(let c = 0; c < valor2.length; c++) {
                valor3[c] = valor2[c].split(' ')
            }

            for(let c = 0; c < valor2.length ; c++){
                
                    //verifica se tem mensagem
                    if(valor3[c][0] == 'Mensagem'){
                        valor3[c].splice(0,1,'-')
                    }

                    //verifica se tem COR
                    if(valor3[c][1] != 'COR'){
                        valor3[c].splice(1,0,'-')
                    }

                    //verifica se tem AUTO
                    if(valor3[c][4] != 'AUTO'){
                        valor3[c].splice(4,0,'-')
                    }

                    //verifica se tem vento variando ex: 120v200
                    if(valor3[c][6].includes("0V") == false){
                        valor3[c].splice(6,0,'-')
                    }

                    //verificar se tem CAVOK
                    if(valor3[c][7] == 'CAVOK'){
                        valor3[c].splice(8,0,'-')
                        valor3[c].splice(9,0,'-')
                        valor3[c].splice(10,0,'-')
                        valor3[c].splice(11,0,'-')
                        valor3[c].splice(12,0,'-')
                        valor3[c].splice(13,0,'-')
                        valor3[c].splice(14,0,'-')
                        valor3[c].splice(15,0,'-')
                        valor3[c].splice(16,0,'-')
                        valor3[c].splice(17,0,'-')
                        valor3[c].splice(18,0,'-')
                        valor3[c].splice(19,0,'-')

                        //verificar se tem tempo recente no CAVOK
                    if(valor3[c].length == 25){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,1,valor3[c][23]);
                        valor3[c].splice(24,1,valor3[c][24]);
                    }
                    else if(valor3[c].length == 24){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,1,valor3[c][23]);
                        valor3[c].splice(24,0, "-");
                    }else if(valor3[c].length == 23){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,0, "-");
                        valor3[c].splice(24,0, "-");
                    }else{
                        valor3[c].splice(22,0, "-");
                        valor3[c].splice(23,0, "-");
                        valor3[c].splice(24,0, "-");
                    }
                    }else{
                    
                    //verificar demais situaçõeo sem o CAVOK    

                    
                    if(valor3[c][8].includes('///////') == true || (valor3[c][8].substring(6, 9) == '///' &&
                    valor3[c][8].includes('R') == false)){
                        valor3[c].splice(8,1,"-");
                        valor3[c].splice(9,0,"-");
                        valor3[c].splice(10,0,"-");
                        valor3[c].splice(11,0,"-");
                        valor3[c].splice(12,0,"-");
                        valor3[c].splice(13,0,"-");
                        valor3[c].splice(14,0,"-");
                        valor3[c].splice(15,0,"-");
                        valor3[c].splice(16,0,"-");
                        valor3[c].splice(17,0,"-");
                        valor3[c].splice(18,0,"-");
                        valor3[c].splice(19,0,"-");
                    }else{

                    //verifica se tem visibilidade mínima
                    if(valor3[c][8].substring(4) != "N" && valor3[c][8].substring(4) != "NE" && valor3[c][8].substring(4) != "E" && valor3[c][8].substring(4) != "SE" && valor3[c][8].substring(4) != "S" && valor3[c][8].substring(4) != "SW" && valor3[c][8].substring(4) != "W" && valor3[c][8].substring(4) != "NW"){
                        valor3[c].splice(8,0,"-");
                    }

                    //verificar se tem rvr pista 1
                    if(valor3[c][9].length < 8 || valor3[c][9].includes('TCU') == true || valor3[c][9].includes('CB') == true){
                        valor3[c].splice(9,0,"-");
                    }
                    //verificar se tem rvr pista 2
                    if(valor3[c][10].length < 8 || valor3[c][10].includes('TCU') == true || valor3[c][10].includes('CB') == true){
                        valor3[c].splice(10,0,"-");
                    }
                    //verificar se tem rvr pista 3
                    if(valor3[c][11].length < 8 || valor3[c][11].includes('TCU') == true || valor3[c][11].includes('CB') == true){
                        valor3[c].splice(11,0,"-");
                    }
                    //verificar se tem rvr pista 4
                    if(valor3[c][12].length < 8 || valor3[c][12].includes('TCU') == true || valor3[c][12].includes('CB') == true){
                        valor3[c].splice(12,0,"-");
                    }

                    //verificar se tem tempo presente 1
                    if(valor3[c][13].substring(0,3) == "NSC" || valor3[c][13].substring(0,3) == "NCD" || valor3[c][13].substring(0,3) == "FEW" || valor3[c][13].substring(0,3) == "SCT" || valor3[c][13].substring(0,3) == "BKN" || valor3[c][13].substring(0,3) == "OVC" || valor3[c][13].substring(0,2) == "VV" ){
                        valor3[c].splice(13,0, "-");
                    }
                    //verificar se tem tempo presente 2
                    if(valor3[c][14].substring(0,3) == "NSC" || valor3[c][14].substring(0,3) == "NCD" || valor3[c][14].substring(0,3) == "FEW" || valor3[c][14].substring(0,3) == "SCT" || valor3[c][14].substring(0,3) == "BKN" || valor3[c][14].substring(0,3) == "OVC" || valor3[c][14].substring(0,2) == "VV" ){
                        valor3[c].splice(14,0, "-");
                    }
                    //verificar se tem tempo presente 3
                    if(valor3[c][15].substring(0,3) == "NSC" || valor3[c][15].substring(0,3) == "NCD" || valor3[c][15].substring(0,3) == "FEW" || valor3[c][15].substring(0,3) == "SCT" || valor3[c][15].substring(0,3) == "BKN" || valor3[c][15].substring(0,3) == "OVC" || valor3[c][15].substring(0,2) == "VV" ){
                        valor3[c].splice(15,0, "-");
                    }

                    //verificar se tem nebulosidade 1
                    if(valor3[c][16].substring(0,3) != "NSC" && valor3[c][16].substring(0,3) != "NCD" && valor3[c][16].substring(0,3) != "FEW" && valor3[c][16].substring(0,3) != "SCT" && valor3[c][16].substring(0,3) != "BKN" && valor3[c][16].substring(0,3) != "OVC" && valor3[c][16].substring(0,2) != "VV"){
                        valor3[c].splice(16,0, '-');
                    }
                    //verificar se tem nebulosidade 2
                    if(valor3[c][17].substring(0,3) != "NSC" && valor3[c][17].substring(0,3) != "NCD" && valor3[c][17].substring(0,3) != "FEW" && valor3[c][17].substring(0,3) != "SCT" && valor3[c][17].substring(0,3) != "BKN" && valor3[c][17].substring(0,3) != "OVC" && valor3[c][17].substring(0,2) != "VV"){
                        valor3[c].splice(17,0, '-');
                    }
                    //verificar se tem nebulosidade 3
                    if(valor3[c][18].substring(0,3) != "NSC" && valor3[c][18].substring(0,3) != "NCD" && valor3[c][18].substring(0,3) != "FEW" && valor3[c][18].substring(0,3) != "SCT" && valor3[c][18].substring(0,3) != "BKN" && valor3[c][18].substring(0,3) != "OVC" && valor3[c][18].substring(0,2) != "VV"){
                        valor3[c].splice(18,0, '-');
                    }
                    //verificar se tem nebulosidade 4
                    if(valor3[c][19].substring(0,3) != "NSC" && valor3[c][19].substring(0,3) != "NCD" && valor3[c][19].substring(0,3) != "FEW" && valor3[c][19].substring(0,3) != "SCT" && valor3[c][19].substring(0,3) != "BKN" && valor3[c][19].substring(0,3) != "OVC" && valor3[c][19].substring(0,2) != "VV"){
                        valor3[c].splice(19,0, '-');
                    }


                    //verificar se tem tempo recente sem CAVOK
                    if(valor3[c].length == 25){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,1,valor3[c][23]);
                        valor3[c].splice(24,1,valor3[c][24]);
                    }
                    else if(valor3[c].length == 24){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,1,valor3[c][23]);
                        valor3[c].splice(24,0, "-");
                    }else if(valor3[c].length == 23){
                        valor3[c].splice(22,1,valor3[c][22]);
                        valor3[c].splice(23,0, "-");
                        valor3[c].splice(24,0, "-");
                    }else{
                        valor3[c].splice(22,0, "-");
                        valor3[c].splice(23,0, "-");
                        valor3[c].splice(24,0, "-");
                    }

                }
            }
            }
            
            return valor3
        }
        return ''
    }


    export default tratarMensagens