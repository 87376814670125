<template>
    <!-- Modal -->
    <div class="modal fade" :id="identificacao" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{titulo}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="mensagemModal" class="modal-body">
            {{mensagemModal}}
        </div>
        <div v-else class="modal-body">
            <slot></slot>
        </div>
        <div class="modal-footer">
            <v-btn small dark color="red" data-bs-dismiss="modal">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    props: ['titulo','mensagemModal','identificacao', 'cor'],
    computed: {
        classBtn(){
            return 'btn btn-' + this.cor || 'btn btn-secondary'
        }
    }
}
</script>