const state = {
    grupoCentral: [],
    token: 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ncnVwby1sb2NhbGlkYWRlcy5oZXJva3VhcHAuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjQwMzczMTA3LCJuYmYiOjE2NDAzNzMxMDcsImp0aSI6IlFEMHdyMUttMlRyQzB0SVIiLCJzdWIiOjIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.Fu5yOAhPGSCGTUzF0ZS_cpp5LCm9MP7lZf2_4SLZV98'
}

const getters = {

}

const mutations = {
    atualizarGrupo: (state, payload) => {
        const indice = state.grupoCentral.findIndex(g => g.id == payload.id)
        state.grupoCentral.splice(indice, 1, payload)
    },
    carregarGrupo: (state, payload) => {
        state.grupoCentral = payload
    },
    criarGrupo: (state, payload) => {
        state.grupoCentral.push(payload)
    },
}

const actions = {
    atualizarGrupo: (context, payload) => {
        context.commit('atualizarGrupo', payload)
    },
    carregarGrupo: (context, payload) => {
        context.commit('carregarGrupo', payload)
    },
    criarGrupo: (context, payload) => {
        context.commit('criarGrupo', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters, 
    mutations,
    actions
}