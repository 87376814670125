<template>
    <div>
        <div class="card">
            <div class="card-header">
            {{titulo}}
                <slot class="col" name="btn"></slot>
               
            </div>
            <div class="card-body">
                <slot name="conteudo"></slot>
            </div>
            </div>
        </div>
</template>

<script>
export default {
    props: ['titulo', 'corpo']
}
</script>
