import exibirMetar from './getters/exibirMetar'
import tratarMensagens from './getters/tratarMensagens'
import errosMetar from './getters/errosMetar'
import explicarErros from './getters/explicarErros'
import localCor from './getters/contador/localCor'
import horaCor from './getters/contador/horaCor'
import localSpeci from './getters/contador/localSpeci'
import horaSpeci from './getters/contador/horaSpeci'


const state = {
    metarVerificacao: '',
}

//como os getters são códigos maiores, preferi importalos de um arquivo externo para melhor manutenção
//o state, mutations, acrtions não precisam sair deste arquivo, pois são pequenos
const getters = {
    exibirMetar, // será apresentado na tabela
    tratarMensagens, // deixa o metar com o mesmo tamanho
    errosMetar, // lança os erros do metar
    explicarErros, // explica os erros nos METAR
    localCor, // local do COR
    horaCor, // Hora do COR
    localSpeci, // local do SPECI
    horaSpeci, // hora do SPECI
}

const mutations = {
    pegarMetar: (state, payload) => {
        state.metarVerificacao = payload
    }
}

const actions = {
    pegarMetar: (context, payload) => {
        context.commit('pegarMetar', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}