const horaSpeci = (state, getters) => {
    if(getters.tratarMensagens){
    
    var mensagemTratada =  getters.tratarMensagens   

        var local = []

        for(let c = 0; c < mensagemTratada.length ; c++){
            if(mensagemTratada[c][0] == 'SPECI'){
                local[c] = mensagemTratada[c][3]
            }

        }

        return local

    }
}

export default horaSpeci