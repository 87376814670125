const errosMetar = (state, getters) => {


    /*var teste = [["METAR", "-", "SBRF" ,"160000Z",
    "-" ,"12010G20KT","-" ,"6000","-","-","-","-","-","TSRA","-","-" ,"FEW015CB","SCT022","SCT025TCU","OVC030" ,
    "20/20" ,"Q1013","-","-","-"],

    ["METAR", "-", "SBRF" ,"160100Z",
    "-" ,"09008KT","-" ,"9999","-","-","-","-","-","-TSRA","-","-" ,"SCT021CB","-","-","-" ,
    "28/23" ,"Q1013=","RERA","-","-"],

    ["METAR", "-", "SBRF" ,"160200Z",
    "-" ,"09008KT","-" ,"9999","-","-","-","-","-","-TSRA","-","-" ,"SCT021CB","-","-","-" ,
    "28/23" ,"Q1013=","RERA","-","-"]]*/
    if(getters.tratarMensagens){
        var valor = []
        for(let c = 0; c < getters.tratarMensagens.length ; c++){
            
            

            //verifica rajada de vento
            if(getters.tratarMensagens[c][5].includes('G') == true && (parseInt(getters.tratarMensagens[c][5].substring(6,8)) - parseInt(getters.tratarMensagens[c][5].substring(3,5)) < 10)){
                valor[c] = 'Erro na rajada'
            }

            //tamanho do campo visibilidade
            if(getters.tratarMensagens[c][7] != 'CAVOK' && getters.tratarMensagens[c][7].length != 4){
                valor[c] = 'Tamanho da visibilidade'
            }

            //Verificar visibilidade 
            if(getters.tratarMensagens[c][7] != 'CAVOK' && parseInt(getters.tratarMensagens[c][7]) <= 5000 && ((getters.tratarMensagens[c][13] == '-') && (getters.tratarMensagens[c][14] == '-')  
            && (getters.tratarMensagens[c][15] == '-'))){
                valor[c] = 'Visibilidade reduzida sem tempo presente'
            }

            //Coordenadas visibilidade mínima
            if( getters.tratarMensagens[c][8] != '-' && getters.tratarMensagens[c][8].includes('N') == false && getters.tratarMensagens[c][8].includes('NE') == false && getters.tratarMensagens[c][8].includes('E') == false && 
            getters.tratarMensagens[c][8].includes('SE') == false && getters.tratarMensagens[c][8].includes('S') == false && getters.tratarMensagens[c][8].includes('SW') == false && 
            getters.tratarMensagens[c][8].includes('W') == false && getters.tratarMensagens[c][8].includes('NW') == false){
                valor[c] = 'Coordenada - visibilidade mínima'
            }

            //verifica visibilidade mínima 7 e 8
            if(parseInt((getters.tratarMensagens[c][8]).substring(0,4)) >=1500 && parseInt((getters.tratarMensagens[c][8]).substring(0,4))*2 >= parseInt((getters.tratarMensagens[c][7]).substring(0,4)) ){
                valor[c] = 'Erro na visibilidade mínima'
            }

            //verifica RVR deve ser informado se qualquer vis. tiver menor que 2000m
            //   9   10   11   12
            if((parseInt(getters.tratarMensagens[c][7]) < 2000 || parseInt((getters.tratarMensagens[c][8]).substring(0,4)) < 2000) && getters.tratarMensagens[c][9] == '-' &&
            getters.tratarMensagens[c][2] != "SBIL" && getters.tratarMensagens[c][2] != "SBTE" && getters.tratarMensagens[c][2] != "SBJP" && 
            getters.tratarMensagens[c][2] != "SBKG" && getters.tratarMensagens[c][2] != "SBPL" && getters.tratarMensagens[c][2] != "SBJU" && 
            getters.tratarMensagens[c][2] != "SBMS" && getters.tratarMensagens[c][2] != "SBUF" && getters.tratarMensagens[c][2] != "SBPB" && 
            getters.tratarMensagens[c][2] != "SBGV" && getters.tratarMensagens[c][2] != "SBVC" && getters.tratarMensagens[c][2] != "SBAC" && 
            getters.tratarMensagens[c][2] != "SBJE" && getters.tratarMensagens[c][2] != "SBTV" && getters.tratarMensagens[c][2] != "SBTC" &&
            getters.tratarMensagens[c][2] != "SBPS" && getters.tratarMensagens[c][2] != "SBAR" && getters.tratarMensagens[c][2] != "SBLE"){
                valor[c] = 'Faltou o RVR'
            }

            //verifica se foi pago RVR nas 2 ou 4 pistas

            if(getters.tratarMensagens[c][9] != '-' && getters.tratarMensagens[c][10] == '-'){
                valor[c] = 'O RVR deve ser informado nas duas cabeceiras.'
            }

            if(getters.tratarMensagens[c][9] != '-' && getters.tratarMensagens[c][10] != '-' && getters.tratarMensagens[c][11] != '-' 
            && getters.tratarMensagens[c][12] == '-'){
                valor[c] = 'O RVR deve ser informado nas quatro cabeceiras.'
            }

            //Tempo presente NÉVOA ÚMIDA
            if((parseInt(getters.tratarMensagens[c][7]) > 5000 || parseInt(getters.tratarMensagens[c][7]) < 1000) && (getters.tratarMensagens[c][13].includes('BR') == true || getters.tratarMensagens[c][14].includes('BR') == true 
            || getters.tratarMensagens[c][15].includes('BR') == true)){
                valor[c] = 'Erro na névoa úmida'
            }

            //Tempo presente NÉVOA SECA
            if(parseInt(getters.tratarMensagens[c][7]) > 5000 && (getters.tratarMensagens[c][13].includes('HZ') == true || getters.tratarMensagens[c][14].includes('HZ') == true 
            || getters.tratarMensagens[c][15].includes('HZ') == true)){
                valor[c] = 'Erro na névoa seca'
            }

            //Tempo presente TROVOADA
            if((getters.tratarMensagens[c][16].includes('CB') == false) && (getters.tratarMensagens[c][17].includes('CB') == false) && 
            (getters.tratarMensagens[c][18].includes('CB') == false) && (getters.tratarMensagens[c][19].includes('CB') == false) &&
             ((getters.tratarMensagens[c][13].includes('TS') == true) || (getters.tratarMensagens[c][14].includes('TS') == true) 
            || (getters.tratarMensagens[c][15].includes('TS') == true))){
                valor[c] = 'trovoada sem CB'
            }

            //Tempo presente NEVOEIRO
            if(parseInt(getters.tratarMensagens[c][7]) >= 1000 && ((getters.tratarMensagens[c][13] == 'FG') || (getters.tratarMensagens[c][14] == 'FG')  
            || (getters.tratarMensagens[c][15] == 'FG'))){
                valor[c] = 'Erro no nevoeiro'
            }

            //Tempo presente nas vizinhanças
            if(parseInt(getters.tratarMensagens[c][7]) < 8000 && (getters.tratarMensagens[c][13].includes('VC') == true)){
                valor[c] = 'Erro de vizinhaças'
            }

            //verificar nuvem CB
            if(((getters.tratarMensagens[c][16].includes('CB') == true) || (getters.tratarMensagens[c][17].includes('CB') == true) || 
            (getters.tratarMensagens[c][18].includes('CB') == true) || (getters.tratarMensagens[c][19].includes('CB') == true)) &&
             ((getters.tratarMensagens[c][13].includes('TS') == false) && (getters.tratarMensagens[c][14].includes('TS') == false) 
            && (getters.tratarMensagens[c][15].includes('TS') == false))){
                valor[c] = 'CB sem trovoada'
            }

            //Verificar FEW na segunda camada
            if((getters.tratarMensagens[c][17].includes('FEW') == true && getters.tratarMensagens[c][17].includes('CB') == false) 
            && getters.tratarMensagens[c][17].includes('TCU') == false) {
                valor[c] = 'FEW na camada errada'
            }

            //Verificar FEW na TERCEIRA camada
            if((getters.tratarMensagens[c][18].includes('FEW') == true && getters.tratarMensagens[c][18].includes('CB') == false) 
            && getters.tratarMensagens[c][18].includes('TCU') == false) {
                valor[c] = 'FEW na camada errada'
            }

            //Verificar FEW na QUARTA camada
            if((getters.tratarMensagens[c][19].includes('FEW') == true && getters.tratarMensagens[c][19].includes('CB') == false) 
            && getters.tratarMensagens[c][19].includes('TCU') == false) {
                valor[c] = 'FEW na camada errada'
            }

            //verificar SCT na TERCEIRA camada
            if((getters.tratarMensagens[c][18].includes('SCT') == true && getters.tratarMensagens[c][18].includes('CB') == false && 
            getters.tratarMensagens[c][18].includes('TCU') == false && getters.tratarMensagens[c][17].includes('CB') == false && 
            getters.tratarMensagens[c][17].includes('TCU') == false)){
                valor[c] = 'SCT na camada errada'
            }

            //Verificar SCT na QUARTA camada
            if((getters.tratarMensagens[c][19].includes('SCT') == true && getters.tratarMensagens[c][19].includes('CB') == false) 
            && getters.tratarMensagens[c][19].includes('TCU') == false) {
                valor[c] = 'SCT na camada errada'
            }

            //Verifica o tamanho do campo de temperatura e ponto de orvalho
            if(getters.tratarMensagens[c][20].length < 5){
                valor[c] = 'Erro no campo de temperatura'
            }

            //Ponto de orvalho maior que a temperatura
            if(parseInt(getters.tratarMensagens[c][20].substring(0,2)) < parseInt(getters.tratarMensagens[c][20].substring(3,5))){
                valor[c] = 'PO maior que Temp.'
            }

            //Verifica o tamanho do campo de pressão
            //if(getters.tratarMensagens[c][0] != '-' && getters.tratarMensagens[c][21].length < 5){
              //  valor[c] = 'Erro no campo de pressão'
            //}

            //verifica tempo recente
            if(getters.tratarMensagens[c-1]){//esse if verifica se tem mensagem anterior

            //VERIFICAR FALTA DO RERA
                if(((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) &&
                (getters.tratarMensagens[c-1][13] == 'RA' || getters.tratarMensagens[c-1][13] == '+RA' || getters.tratarMensagens[c-1][13] == 'TSRA' || getters.tratarMensagens[c-1][13] == '+TSRA')
                && (getters.tratarMensagens[c][13] != 'RA' && getters.tratarMensagens[c][13] != '+RA' && (getters.tratarMensagens[c][13] == 'TS' || getters.tratarMensagens[c][13] == '-TSRA') && getters.tratarMensagens[c][13] != 'TSRA' && getters.tratarMensagens[c][13] != '+TSRA')
                 && (getters.tratarMensagens[c][22] != 'RERA')) || (getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && (getters.tratarMensagens[c-1][0] == 'SPECI' && getters.tratarMensagens[c-1][22] == 'RERA' &&  (getters.tratarMensagens[c][13] != 'RA' && getters.tratarMensagens[c][13] != '+RA' && (getters.tratarMensagens[c][13] ==
                  'TS' || getters.tratarMensagens[c][13] == '-TSRA') && getters.tratarMensagens[c][13] != 'TSRA' && getters.tratarMensagens[c][13] != '+TSRA') && getters.tratarMensagens[c][22] != 'RERA')){
                     
                    valor[c] = 'Faltou RERA'

                 }

            //RERA desnecessário com relação ao metar anterior
            if((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && 
            (getters.tratarMensagens[c-1][13] != 'RA' && getters.tratarMensagens[c-1][13] != '+RA' && getters.tratarMensagens[c-1][13] != 'TSRA' && getters.tratarMensagens[c-1][13] != '+TSRA') 
            && getters.tratarMensagens[c-1][0] != 'SPECI' && getters.tratarMensagens[c-1][22] != 'RERA' && getters.tratarMensagens[c][22] == 'RERA'){
                valor[c] = 'RERA incorreto'
            }     

            //Verifica falta do RESHRA
            if(((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && 
                (getters.tratarMensagens[c-1][13] == 'SHRA' || getters.tratarMensagens[c-1][13] == '+SHRA') && (getters.tratarMensagens[c][13] != 'SHRA' && getters.tratarMensagens[c][13] != '+SHRA' && getters.tratarMensagens[c][22] != 'RESHRA')) 
            || (getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && (getters.tratarMensagens[c-1][0] == 'SPECI' && getters.tratarMensagens[c-1][22] == 'RESHRA' && getters.tratarMensagens[c][13] != 'SHRA' && 
            getters.tratarMensagens[c][13] != '+SHRA' && getters.tratarMensagens[c][22] != 'RESHRA')){
                valor[c] = 'Faltou RESHRA'
            }

            //RESHRA desnecessário com relação ao metar anterior
            if((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && 
                (getters.tratarMensagens[c-1][13] != 'SHRA' && getters.tratarMensagens[c-1][13] != '+SHRA' 
            && getters.tratarMensagens[c-1][0] != 'SPECI' && getters.tratarMensagens[c][22] == 'RESHRA')
            || (getters.tratarMensagens[c-1][0] == 'SPECI' && getters.tratarMensagens[c-1][13] != 'SHRA' 
            && getters.tratarMensagens[c-1][13] != '+SHRA' && getters.tratarMensagens[c-1][22] != 'RESHRA' && 
            getters.tratarMensagens[c][22] == 'RESHRA')){
                valor[c] = 'RESHRA incorreto'
            }

            //RETS desnecessário com relação ao metar anterior
            if((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) &&
                (getters.tratarMensagens[c-1][13] != 'TS' && getters.tratarMensagens[c-1][13] != '-TSRA' && getters.tratarMensagens[c-1][13] != 'TSRA'
             && getters.tratarMensagens[c-1][13] != '+TSRA' && getters.tratarMensagens[c-1][0] != 'SPECI' && getters.tratarMensagens[c][22] == 'RETS') || (getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && (getters.tratarMensagens[c-1][0] == 'SPECI' && getters.tratarMensagens[c-1][13] != 'TS' && getters.tratarMensagens[c-1][13] != '-TSRA' && getters.tratarMensagens[c-1][13] != 'TSRA'
             && getters.tratarMensagens[c-1][13] != '+TSRA' && getters.tratarMensagens[c-1][22] != 'RETS' && getters.tratarMensagens[c][22] == 'RETS')){
                valor[c] = 'RETS incorreto'
            } 
            
            
            //RETSRA desnecessário com relação ao metar anterior
            if((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2])
             && (getters.tratarMensagens[c-1][13] != 'TSRA' && getters.tratarMensagens[c-1][13] != '+TSRA') && getters.tratarMensagens[c][22] == 'RETSRA'){
                valor[c] = 'RETSRA incorreto'
            } 


            //Verificar falta de RETS
            if(((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) 
                && (getters.tratarMensagens[c-1][13] == 'TS' || getters.tratarMensagens[c-1][13] == 'TSRA' || getters.tratarMensagens[c-1][13] == '+TSRA' 
            || getters.tratarMensagens[c-1][13] == '-TSRA')
             && getters.tratarMensagens[c][13].includes('TS') == false && (getters.tratarMensagens[c][13] == 'RA' || getters.tratarMensagens[c][13] == '+RA')
              && getters.tratarMensagens[c][22] != 'RETS') || (getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && (getters.tratarMensagens[c-1][0] == 'SPECI' 
            && getters.tratarMensagens[c-1][22] == 'RETS' && getters.tratarMensagens[c][13].includes('TS') == false && getters.tratarMensagens[c][22] != 'RETS')){
                valor[c] = 'Faltou RETS'
            }
            
            //Verificar falta de RETSRA
            if(((getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2])
            && (getters.tratarMensagens[c-1][13] == 'TSRA' || getters.tratarMensagens[c-1][13] == '+TSRA') && getters.tratarMensagens[c][13] != 'TS' && 
            getters.tratarMensagens[c][13] != '-TSRA' && getters.tratarMensagens[c][13] != 'TSRA' && getters.tratarMensagens[c][13] != '+TSRA' &&
            getters.tratarMensagens[c][13] != 'RA' && getters.tratarMensagens[c][13] != '+RA' && getters.tratarMensagens[c][22] != 'RETSRA')
            || (getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c-1][2] == getters.tratarMensagens[c][2]) && (getters.tratarMensagens[c-1][0] == 'SPECI' && getters.tratarMensagens[c-1][22] == 'RETSRA' && getters.tratarMensagens[c][13] != 'TS'
            && getters.tratarMensagens[c][13] != '-RATS' && getters.tratarMensagens[c][13] != 'TSRA' && getters.tratarMensagens[c][13] != '+TSRA' 
            && getters.tratarMensagens[c][13] != 'RA' && getters.tratarMensagens[c][13] != '+RA' && getters.tratarMensagens[c][22] != 'RETSRA')){
                valor[c] = 'Faltou RETSRA'
            }    

            }

            //Casos de tempo recente que não precisa de mensagem anterior
            //RERA desnecessário
            if((getters.tratarMensagens[c][13] == 'RA' || getters.tratarMensagens[c][13] == '+RA' 
            || getters.tratarMensagens[c][13] == 'TSRA' || getters.tratarMensagens[c][13] == '+TSRA') && getters.tratarMensagens[c][22] == 'RERA'){

                valor[c] = 'RERA incorreto'

            }

            //RESHRA desnecessário
            if((getters.tratarMensagens[c][13] == 'SHRA' || getters.tratarMensagens[c][13] == '+SHRA') && getters.tratarMensagens[c][22] == 'RESHRA'){

                valor[c] = 'RESHRA incorreto'

            }

            //RETS desnecessário
            if((getters.tratarMensagens[c][13] == 'TS' || getters.tratarMensagens[c][13] == 'TSRA' || getters.tratarMensagens[c][13] == '+TSRA' 
            || getters.tratarMensagens[c][13] == '-TSRA') && getters.tratarMensagens[c][22] == 'RETS'){

                valor[c] = 'RETS incorreto'

            }

            //RETSRA desnecessário
            if((getters.tratarMensagens[c][13] == 'TS' || getters.tratarMensagens[c][13] == 'TSRA' || getters.tratarMensagens[c][13] == '+TSRA' 
            || getters.tratarMensagens[c][13] == '-TSRA' || getters.tratarMensagens[c][13] == 'RA' || getters.tratarMensagens[c][13] == '+RA') 
            && getters.tratarMensagens[c][22] == 'RETSRA'){

                valor[c] = 'RETSRA incorreto'

            }

            //verifica se tem mensagem
            if(getters.tratarMensagens[c][0] == '-'){
                valor[c] = 'Ausente'
            }

            //.........................Analisar casos de SPECIS...................................//

            /*if(getters.tratarMensagens[c][0] == 'SPECI' && getters.tratarMensagens[c-1][0] != '-' && getters.tratarMensagens[c+1][0] != '-'){

                //Se não entrar em nenhuma condição de SPECI o status será esse
                //Essa analise conta que tenham mensagens antes e depois do SPECI
                //Será atribuido esse texto para os casos que não tenha para trás e para frente
                valor[c] = 'Não foram encontrado motivos para confecção do SPECI'
                

            } */
            

        }
        return valor
    }
    return ''
}

export default errosMetar




