<template>
    
<modal titulo="Deixe uma sugestão." identificacao="sugestao" cor="danger">
    <div class="alert alert-warning" role="alert">
        <p>Preencha os compos corretamente para que possamos entrar em contato, caso seja necessário.</p>
    </div>

    <div v-if="alertaSucesso" class="alert alert-primary" role="alert">
            <p>{{mensagemSucesso}}</p>
    </div>

    <div v-if="alertaErro" class="alert alert-danger" role="alert">
            <p>{{mensagemErro}}</p>
    </div>
    <form ref="form" @submit.prevent="sendEmail">
            <div class="form-group">
                <v-text-field label="Seu nome" name="nome" v-model="usuario.nome"></v-text-field>
            </div>
            <div class="form-group">
                <v-text-field label="Seu email" type="mail" name="email" v-model="usuario.email"></v-text-field>
            </div>
            <div class="form-group">

                <v-textarea
                class="mx-2"
                label="Escreva aqui sua sugestão"
                rows="3"
                v-model="usuario.msg"
                name="msg"
                ></v-textarea>
            </div>
            <v-btn color="green" dark class="mt-3" type="submit">
                Enviar
            </v-btn>
        </form>

    <!--<form ref="form" @submit.prevent="sendEmail">
      <label>Nome</label>
      <input type="text" name="user_name">
      <label>Email</label>
      <input type="email" name="user_email">
      <label>Mensagem</label>
      <textarea name="message"></textarea>
      <input type="submit" value="Send">
    </form>-->
</modal>
</template>

<script>
import emailjs from '@emailjs/browser';
import modal from './modal.vue'

export default {
    components: {
        modal
    },
    data() {
        return {
            usuario: {nome: '', email: '', msg: ''},
            alertaSucesso: false,
            alertaErro: false,
            mensagemSucesso: '',
            mensagemErro: '',
        }
    },
  methods: {
    sendEmail() {
      emailjs.sendForm('service_r6693ch', 'formulario_de_contato', this.$refs.form, 'YtZPbqzwonOebyDLF')
        .then(() => {
            this.alertaSucesso = true
            this.mensagemSucesso = "Enviado com sucesso!";
        }, (erro) => {
            this.alertaErro = true
            this.mensagemErro = erro + " Erro ao enviar a mensagem, tente novamente mais tarde.";

        });
        setTimeout(() => {
            this.alertaErro = false
            this.alertaSucesso = false
            this.$refs.form.reset
            this.usuario = {nome: '', email: '', msg: ''}
        }, 5000);
    }
  }
}
</script>