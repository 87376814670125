//esse arquivo seleciona as mensagens para ser exibidas dentro da tabela
const exibirMetar = state => {
        if(state.metarVerificacao) {
            var valor = []
            
            for(let c = 1; c < state.metarVerificacao.split('- ').length ; c++){
                valor[c-1] = state.metarVerificacao.split('- ')[c];
            }

            var valor2 = []
            for(let c = 0; c < valor.length; c++){
                    valor2[c] = valor[c].split('=')[0] + '='
            }
            return valor2
        }
        return ''
    }

    export default exibirMetar