const state = {
    DataHoraZulu: ''
}

const getters = {
    horaZuluFormatada: state => {
        if (!state.DataHoraZulu) return ''; // Retorna vazio se não houver valor
        return state.DataHoraZulu.split('T')[1].split('.')[0];
    },
    dataZuluFormatada: state => {
        if (!state.DataHoraZulu) return ''; // Retorna vazio se não houver valor
        return state.DataHoraZulu.split('.')[0].replace(/-/g, '').split('T')[0];
    }
}

const mutations = {
    modificarDataHoraZulu: (state, payload) => {
        state.DataHoraZulu = payload
    }
}

const actions = {
    modificarDataHoraZulu: (context, payload) => {
        context.commit('modificarDataHoraZulu', payload)
    }
}

export default{
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}