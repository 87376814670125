import Vue from 'vue'
import Vuex from 'vuex'

import DataHoraZulu from './../modules/DataHoraZulu'
import GrupoMetar from './../modules/GrupoMetar'
import _store from '../modules/_store'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        DataHoraZulu,
        GrupoMetar,
        _store,
    }
})


export default store