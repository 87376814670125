const explicarErros = (state, getters) => {
    if(getters.errosMetar) {
        var erros = getters.errosMetar
        var mensagem = []

        for(let c = 0 ; c < erros.length ; c++){
            if(erros[c] == 'Ausente'){
                mensagem[c] = 'Mensagem não consta no banco OPMET'
            }

            if(erros[c] == 'Erro na rajada'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.1.4.'
            }

            if(erros[c] == 'Tamanho da visibilidade'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.2'
            }

            if(erros[c] == 'Visibilidade reduzida sem tempo presente'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.4.3'
            }

            if(erros[c] == 'Coordenada - visibilidade mínima'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.2.5, alínea b)'
            }

            if(erros[c] == 'Erro na visibilidade mínima'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.2.5, alínea b)'
            }

            if(erros[c] == 'Faltou o RVR'){
                mensagem[c] = 'Depende da categoria a que se destina a pista. Explicação na ICA 105-15, Item 4.3.6'
            }

            if(erros[c] == 'O RVR deve ser informado nas duas cabeceiras.'){
                mensagem[c] = 'Consulte a publicação específica ou órgão regional.'
            }

            if(erros[c] == 'O RVR deve ser informado nas quatro cabeceiras.'){
                mensagem[c] = 'Consulte a publicação específica ou órgão regional.'
            }

            if(erros[c] == 'Erro na névoa úmida'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.4.3, alínea b)'
            }

            if(erros[c] == 'Erro na névoa seca'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.4.3, alínea c)'
            }

            if(erros[c] == 'trovoada sem CB'){
                mensagem[c] = 'O tempo presente "TS" deve ser acompanhado da nuvem CB'
            }

            if(erros[c] == 'Erro no nevoeiro'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.4.3, alínea b)'
            }

            if(erros[c] == 'Erro de vizinhaças'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.4.5, alínea b) NOTA'
            }

            if(erros[c] == 'CB sem trovoada'){
                mensagem[c] = 'Nuvem CB está associada a trovoadas'
            }

            if(erros[c] == 'FEW na camada errada'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.5.5, alínia c)'
            }

            if(erros[c] == 'SCT na camada errada'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.5.5, alínia c)'
            }

            if(erros[c] == 'Erro no campo de temperatura'){
                mensagem[c] = 'Faltando digito(s).'
            }

            if(erros[c] == 'PO maior que Temp.'){
                mensagem[c] = 'O ponto de orvalho não pode ser maior que a temperatura'
            }

            if(erros[c] == 'Erro no campo de pressão'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.8.2'
            }

            if(erros[c] == 'Faltou RERA'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'RERA incorreto'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'Faltou RESHRA'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'RESHRA incorreto'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'Faltou RETS'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'RETS incorreto'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'RETSRA incorreto'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }

            if(erros[c] == 'RETSRA incorreto'){
                mensagem[c] = 'Explicação na ICA 105-15, Item 4.9.2.1'
            }


            
        }

        return mensagem

    }

    return ''
}

export default explicarErros