const localSpeci = (state, getters) => {
    if(getters.tratarMensagens){
    
    var mensagemTratada =  getters.tratarMensagens   

        var hora = []

        for(let c = 0; c < mensagemTratada.length ; c++){
            if(mensagemTratada[c][0] == 'SPECI'){
                hora[c] = mensagemTratada[c][2]
            }

        }

        return hora

    }
}

export default localSpeci