<template>
    <div class="container">
        <form @submit.prevent="login">
            <div class="form-group">
                <label for="nome" class="form-label">Nome</label>
                <input type="text" id="nome" class="form-control" v-model="nome">
            </div>
            <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input type="mail" id="email" class="form-control" v-model="email">
            </div>
            <div class="form-group">
                <label for="senha" class="form-label">Senha</label>
                <input type="Password" id="senha" class="form-control" v-model="senha">
            </div>
            <div class="form-group mt-2">
                <input type="submit" value="Criar" class="btn btn-primary">
            </div>
        </form>
    </div>
</template>

<script>

import axios from  'axios'
import apiUrl from './../APIs/apiUrl'
import bcrypt from 'bcryptjs'


export default {
    data () {
        return {
            nome: '',
            email: '',
            senha: ''
        }
    },
    methods: {
        login() {
            const password = this.senha
            const round = 10

            bcrypt.hash(password,round, (err, hash) => {
                if(err) {
                    console.error(err)
                    return 
                }
                axios.post(apiUrl.grupoMetarLogin.apiUrlLogin(this.nome, this.email, hash))
            .then(() => {
                axios.post(apiUrl.grupoMetarLoginPuxar.apiUrlLogin(this.email, this.senha))
                .then(response => {
                    console.log('token de acesso novo: bearer', response.data.access_token)
                })
                this.nome =''
                this.email=''
                this.senha= ''
            })
            })
            
        }
    }
}
</script>